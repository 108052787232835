<template>
  <div id="app">
    <!-- <div class="dev-bar">
      <div class="dev-bar-name">Development bar</div>
      <div class="dev-bar-users">
        <div class="dev-bar-title">User</div>
        <div class="dev-bar-item"> id : {{ $store.state.USER.infos.id}}</div>
        <div class="dev-bar-item"> role : {{ $store.state.USER.role}}</div>
        <div class="dev-bar-item"> token : {{ $store.state.JWT_TOKEN}}</div>
      </div>
      <div class="dev-bar-road">
        <div class="dev-bar-title">Misc</div>
        <div class="dev-bar-item"> road name : {{ $route.name}}</div>
        <div class="dev-bar-item"> road params : {{ $route.params}}</div>
        <div class="dev-bar-item"> current time : {{ currentTime }}</div>
        <div class="dev-bar-item"> session expired at : {{ $store.state.EXPIRED}}</div>
        <div class="dev-bar-item"> current module : {{ $store.state.CURRENT_MODULE}}</div>
      </div>
      <div class="dev-bar-tools">
        <div class="dev-bar-title">Tools</div>
          <a target="_blank" :href="$store.state.URL + 'stats/cron'"  class="dev-bar-item">Launch cron stats</a>
          <a target="_blank" :href="$store.state.URL + 'monitoring/cron'"  class="dev-bar-item">Launch cron monitoring</a>
          <select v-model="lang" @change="changeLang">
            <option
              v-for="(lang, i) in languageArray"
              :key="`lang${i}`"
              :value="lang"
            >
              {{ lang }}
            </option>
          </select>
      </div>
    </div> -->
    <HeaderApp v-if="this.$route.name !='Login' && this.$route.name !='ResetPwd'"></HeaderApp>
    <router-view></router-view>
    <FooterApp v-if="this.$route.name !='Login' && this.$route.name !='ResetPwd'"></FooterApp>
    <Notifs></Notifs>
  </div>
</template>

<script>
import i18n from './i18n'
import HeaderApp from './components/HeaderApp'
import FooterApp from './components/FooterApp'
import Notifs from './components/Notifs'


export default {
  name: 'App',
  data() {
      return {
          languageArray: ['en', 'fr'],
          lang : i18n.locale,
          currentTime : null
      }
  },
  components : {
    HeaderApp,
    FooterApp,
    Notifs
  },
  methods: {
    changeLang() {
      this.$store.dispatch('changeLocale', this.lang)
    },
    goRouter(e) {
      this.$router.push(e.target.value)
    },
    // checkTime() {
    //   setInterval(() => {
    //       var d = new Date();
    //       this.currentTime = d.toLocaleTimeString();
    //   }, 1000);

    // }
  },
  // created() {
  //     this.checkTime()
  // }
}
</script>

<style lang="scss">
body, h1, h2, h3 {
  margin:0;
  padding:0;
}

body {
  font-family: $font;
  font-size:15px;
}


/* ./assets/fonts */
@font-face {
	font-family: 'Conv_Gotham-Black';
	src: url('./assets/fonts/Gotham-Black.eot');
	src: local('☺'), url('./assets/fonts/Gotham-Black.woff') format('woff'), url('./assets/fonts/Gotham-Black.ttf') format('truetype'), url('./assets/fonts/Gotham-Black.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_GothamBook';
	src: url('./assets/fonts/GothamBook.eot');
	src: local('☺'), url('./assets/fonts/GothamBook.woff') format('woff'), url('./assets/fonts/GothamBook.ttf') format('truetype'), url('./assets/fonts/GothamBook.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Gotham-Medium';
	src: url('./assets/fonts/Gotham-Medium.eot');
	src: local('☺'), url('./assets/fonts/Gotham-Medium.woff') format('woff'), url('./assets/fonts/Gotham-Medium.ttf') format('truetype'), url('./assets/fonts/Gotham-Medium.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'Conv_Gotham-Thin';
	src: url('./assets/fonts/Gotham-Thin.eot');
	src: local('☺'), url('./assets/fonts/Gotham-Thin.woff') format('woff'), url('./assets/fonts/Gotham-Thin.ttf') format('truetype'), url('./assets/fonts/Gotham-Thin.svg') format('svg');
	font-weight: normal;
	font-style: normal;
}


#app {
    min-height: 100vh;
    width:100%;
    background-image:url('./assets/bg-login.jpg');
    background-size:cover;
    background-position: center;
    background-repeat: no-repeat;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.btn {
    display:flex;
    justify-content: center;
    align-items: center;
    color:#fff;
    font-size:18px;
    letter-spacing: 1px;
    height:46px;
    border-radius:10px;
    background-color:$mainColor;
    transition: 0.5s;
    cursor: pointer;
    font-family: $fontMedium;
    border:2px solid $mainColor;
    transition: 0.5s;
}

.btn:hover {
  background:#fff ;
  color: $mainColor;
}

input, textarea {
  appearance: none;
  border:1px solid transparent;
  font-family: $font;
}

input:focus, textarea:focus, select:focus {
  outline:none;
  border:1px solid $mainColor;
}

.input-required {
  color:$error;
  margin-left:3px;
}


h1 {
  color:$mainColor;
}

p {
  font-family: $font;
}

.switch-lang {
  position: absolute;
  top:5px;
  right:5px;
}

.temp-menu {
  position: absolute;
  top:5px;
  left:5px;
}

.notice-error {
  color:$error;
  font-size:12px;
  margin-bottom:5px;
  font-family: $font;
}

.input-error, .input-default input.input-error, .input-default select.input-error {
  border:1px solid $error;  
}

.input-default {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.input-default input, .input-default select, .input-default textarea {
  width:calc(100% - 40px);
  padding:15px 20px;
  background:$inputBackground;
  border-radius:10px;
  font-size:15px;
  font-family: $font;
  color:$textColor; 
  margin:5px 0;
  border:1px solid transparent;
}

.input-default input::placeholder, .disabled {
  color:$textGrey !important;
  font-style: italic;
}

.input-default select {
  width:100%;
  padding:15px 20px;
  background:$inputBackground;
  border-radius:10px;
  font-size:15px;
  font-family: $font;
  color:$textColor; 
  margin:5px 0;
  border:1px solid transparent;
}

.input-default option {
  font-size:15px;
  font-family: $font;
  color:$textColor;
}

.input-default input:disabled {
  opacity: 0.5;
}

.input-default.disabled {
  pointer-events: none;
  opacity: 0.5;
}

input[type="date"]::before{
    content: attr(placeholder)" : ";
    white-space: pre;
}

input[type="date"]:focus::before {
    content:""!important;
}

input[type="date"] {
  padding:13px 20px;
}

.input-default input::placeholder {
  color:$textColor;
}


.btn-form {
  background:$mainColor;
  display: flex;
  justify-content: center;
  width: 100%;
  padding:10px 0;
  color:#fff;
  font-size:18px;
  border-radius:10px;
  cursor: pointer;
  font-family: $fontMedium;
  letter-spacing: 1px;
}

form.disabled-edit input, form.disabled-edit select, form.disabled-edit textarea {
    pointer-events: none;
}


/* colonnes */
.row {
  display: flex;
  width:100%;
}

.col25 {
  width:25%;
}

.col50 {
  width:50%;
}

.col75 {
  width:75%;
}

.col100 {
  width:96%;
  margin:0 2%;
}


/* TABLES */


.tab-header {
    display:flex;
    justify-content: space-between;
    align-items: center;
    height:50px;
}

.tab-switcher {
    display:flex;
    align-items: center;
    height:50px;
}

.tab-sep {
    height:20px;
    width:1px;
    background: $textGrey;
    margin:0 10px;
}

.tab-switcher-item {
    font-size:20px;
    font-family: $font;
    color:$textColor;
    cursor: pointer;
    transition: 0.5s;
}

.tab-switcher-item.active, .tab-switcher-item:hover {
    color:$mainColor;
}

table {
  width:100%;
  border-collapse: collapse;
  margin-top:20px;
}

th {
  background-color:$mainColor;
  color:#fff;
  font-size:15px;
  font-family: $font;
  padding:10px;
  cursor: pointer;
  position:relative
}

th.sort-asc:after, th.sort-desc:after {
  position: absolute;
  content:'';
  right:2px;
  height:10px;
  width:10px;
  top:calc(50% - 5px);
  background-image: url('./assets/icon-arrow.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  transition: 0.5s;
  transform: rotate(180deg);
}

th.sort-desc:after {
  transform: rotate(0deg);
}

th.no-hover {
  cursor: inherit;
}

th:last-child {
  cursor: inherit;
}

table  tbody tr td {
  background-color: $inputBackground;
  padding:10px;
  font-size: 15px;
  font-family: $font;
  color:$mainColor;
  text-align: center;
  line-height: 20px;
}


table  tbody tr:nth-child(odd) td {
  background-color:#fff;
}

.tab-add-btn {
    padding:10px;
    text-transform: uppercase;
    font-family: $font;
    height:auto;
    font-size:15px;
}

.tab-actions {
  display: flex;
  justify-content: center;
}

.tab-btn {
  margin:0 10px;
  height:25px;
  width: 25px;
  cursor: pointer;
}

.tab-edit {
  background-image: url('./assets/icon-edit.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tab-edit-pathology {
  background-image: url('./assets/icon-update-pathology.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tab-delete {
  background-image: url('./assets/icon-delete.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tab-add {
  background-image: url('./assets/icon-add-pathology.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tab-list {
  background-image: url('./assets/icon-eye.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tab-pdf {
  background-image: url('./assets/icon-pdf.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.tab-btn.tab-relaunch {
  background-image: url('./assets/icon-relaunch.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  position:relative;
  height:35px;
  width: 35px;
  min-height: 35px;
  min-width: 35px;
}

.tab-btn.tab-relaunch.relaunch-alert {
    background-image: url('./assets/icon-relaunch-failed.png');
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
}

.relaunch-notif {
  position: absolute;
  height:22px;
  width:22px;
  border-radius:50%;
  background:$mainColor;
  color:#fff;
  font-size:12px;
  font-weight:600;
  display: flex;
  justify-content: center;
  align-items: center;
  right:-10px;
  top:-10px;
}

.relaunch-alert .relaunch-notif {
  background:$error;
}

/* MODAL DEFAULT */
.modal {
    background:rgba(0,0,0, 0.5);
    position: fixed;
    top:0;
    left:0;
    right:0;
    bottom:0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.modal.is-safari {
    display: block;

}

.modal-close {
    position: absolute;
    top:15px;
    right:15px;
    height:20px;
    width:20px;
    background: url('./assets/icon-close.png') ;
    background-size: 20px;
    background-repeat: no-repeat;
    cursor: pointer;
}

.modal-container {
    background: #fff;        
    border-radius:10px;
    max-width:90vw;
    width: 700px;
    overflow-y: auto;
    max-height: 90%;
}

.modal.is-safari .modal-container {
    white-space:nowrap;
    overflow: scroll;
    -webkit-overflow-scrolling: touch;
    max-height: 80%;
    margin:5% auto;
}

.form-add {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-add h2 {
  font-size:20px;
  letter-spacing: 1px;
  color:$mainColor;
  font-family: $fontBlack;
  text-transform:uppercase;
  margin-bottom:40px;
  margin-top:40px;
}

.form-add span, .form-add form  {
  width:100%;
}

.form-add form {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.form-add .row {
  margin:10px 0;
  width: 90%;
}

.form-add .row .col50 {
  width:46%;
  margin:0 2%
}

.form-add .row .col33 {
  width:29.3%;
  margin:0 2%
}

.form-add .input-submit {
  display: flex;
  justify-content: center;
  align-items: center;
  background:$mainColor;
  width:100%;
  height:50px;
  color:#fff;
  cursor: pointer;
  font-size:20px;
  font-family: $fontMedium;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  transition:0.5s;
}

.form-add .input-submit:hover{
  background: darken($mainColor, 20);
  border-color: darken($mainColor, 20);
}

.pathology-container {
  display:flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 800px;
  max-width: 90%;
  margin:20px auto;
}

.pathology-container h2 {
  font-size:20px;
  letter-spacing: 1px;
  font-family: $fontBlack;
  margin-bottom:20px;
}

.timeline-container {
    display: flex;
    justify-content: center;
    margin:30px 0;
}

.pathology-step:first-child .pathology-line {
    left:50%;
}

.pathology-step:last-child .pathology-line {
    right:50%;
}

//tooltip

.tooltip .tooltip-inner {
  background: $mainColor;
  color: #fff;
  border-radius: 5px;
  padding: 5px 10px;
  margin-bottom: 5px;
  box-shadow: 0 0 5px rgba(0,0,0,0.3);
}

.back {
    height:40px;
    width: 40px;
    background-image: url('./assets/back.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    cursor: pointer;
}

@media screen and (max-width:768px) {
  .btn {
    font-size:15px;
    height:40px;
  }
}


/**************************************
* DEV BAR
***************************************/
.dev-bar {
  background:$inputBackgroundLogin;
  padding:5px 20px;
  display: flex;
  font-size:12px;
}

.dev-bar-name {
  width:15%;
  margin-top:20px;
  font-weight:bold;
  font-style:italic
}

.dev-bar-users {
  width:40%;
}

.dev-bar-tools {
  width:20%
}

.dev-bar-road {
  width:25%;
}

.dev-bar-title {
  font-weight:bold;
  font-style:italic;
  text-decoration: underline;
  margin-bottom: 5px;
}

.dev-bar-item {
  display: flex;
  word-break: break-all;
  margin-right:20px;
}

a.dev-bar-item {
  text-decoration: none;
  color:$textColor;
}

a.dev-bar-item:hover{
  cursor: pointer;
  text-decoration: underline;
}

</style>
