<template>
    <footer>
        <div class="footer-container">
            <p><span>Copyright © {{ currentYear }} REMEDEX – <router-link :to="{name : 'LegalNotices' }">{{ $t('legalNotices.main')}}</router-link></span> <span class="hide-resp">–</span> <span>Design by <a href="https://www.synthes3dweb.com/" target="_blank">SYNTHES’3D WEB</a></span></p>
        </div>
    </footer>
</template>

<script>
export default {
    name : "FooterApp",
    data() {
        return {
            currentYear : new Date().getFullYear() 
        }
    }
}
</script>

<style lang="scss" scoped>
footer {
    height:40px;
    display: flex;
    justify-content: center;
    background:$mainColor;
    width: 100%;
}

.footer-container {
    width:1600px;
    max-width: 90%;
    display: flex;
    justify-content: center;
    align-items: center;
    height:40px;
    color:#fff;
    font-size:15px;
}

.footer-container p {
    margin:0;
}

.footer-container a {
    color:#fff;
    text-decoration: none;
}

.footer-container a:hover {
    cursor: pointer;
    text-decoration: underline;
}

@media screen and (max-width: 768px) {
    .footer-container p {
        font-size:12px;
        text-align: center;
        display: flex;
        flex-direction: column;
    }

    .hide-resp {
        display: none;
    }
}
</style>