<template>
    <div class="notifs-container" v-if="this.$store.state.NOTIFS && this.$store.state.NOTIFS.length > 0">
            <div :class="['notif-item', item.type == 1 ? 'success' : 'error']" v-for="(item, idx) in this.$store.state.NOTIFS" :key="idx">
                <div class="notif-close" @click="removeNotif(idx)"></div>
                <p class="notif-content" v-if="item.content == 1">{{ $t('notifs.userAdd')}}</p>
                <p class="notif-content" v-else-if="item.content == 2">{{ $t('notifs.userUpdate')}}</p>
                <p class="notif-content" v-else-if="item.content == 3">{{ $t('notifs.userDelete')}}</p>
                <p class="notif-content" v-else-if="item.content == 4">{{ $t('notifs.authorization')}}</p>
                <p class="notif-content" v-else-if="item.content == 5">{{ $t('notifs.mailSend')}}</p>
                <p class="notif-content" v-else-if="item.content == 6">{{ $t('notifs.adressEmailNotFound')}}</p>
                <p class="notif-content" v-else-if="item.content == 7">{{ $t('notifs.passwordReset')}}</p>
                <p class="notif-content" v-else-if="item.content == 8">{{ $t('notifs.passwordInvalid')}}</p>
                <p class="notif-content" v-else-if="item.content == 9">{{ $t('notifs.updateMyAccount')}}</p>
                <p class="notif-content" v-else-if="item.content == 10">{{ $t('notifs.updateMyPassword')}}</p>
                <p class="notif-content" v-else-if="item.content == 11">{{ $t('notifs.deleteMyAccount')}}</p>
                <p class="notif-content" v-else-if="item.content == 12">{{ $t('notifs.logout')}}</p>
                <p class="notif-content" v-else-if="item.content == 13">{{ $t('notifs.addPathology')}}</p>
                <p class="notif-content" v-else-if="item.content == 14">{{ $t('notifs.updatePathologyFollow')}}</p>
                <p class="notif-content" v-else-if="item.content == 15">{{ $t('notifs.deletePathology')}}</p>
                <p class="notif-content" v-else-if="item.content == 16">{{ $t('notifs.updateStade')}}</p>
                <p class="notif-content" v-else-if="item.content == 17">{{ $t('notifs.addInjection')}}</p>
                <p class="notif-content" v-else-if="item.content == 18">{{ $t('notifs.updateInjection')}}</p>
                <p class="notif-content" v-else-if="item.content == 19">{{ $t('notifs.uploadFile')}}</p>
                <p class="notif-content" v-else-if="item.content == 20">{{ $t('notifs.uploadFileFailed')}}</p>
                <p class="notif-content" v-else-if="item.content == 21">{{ $t('notifs.deleteFile')}}</p>
                <p class="notif-content" v-else-if="item.content == 22">{{ $t('notifs.updateResults')}}</p>
                <p class="notif-content" v-else-if="item.content == 23">{{ $t('notifs.finishResults')}}</p>
                <p class="notif-content" v-else-if="item.content == 24">{{ $t('notifs.acceptance')}}</p>
                <p class="notif-content" v-else-if="item.content == 25">{{ $t('notifs.area')}}</p>
                <p class="notif-content" v-else-if="item.content == 26">{{ $t('notifs.fileAdd')}}</p>
                <p class="notif-content" v-else-if="item.content == 27">{{ $t('notifs.activate')}}</p>
                <p class="notif-content" v-else-if="item.content == 28">{{ $t('notifs.archive')}}</p>
                <p class="notif-content" v-else v-html="item.content"></p>
            </div> 
    </div>
</template>

<script>
export default {
    name : 'Notifs',
    methods : {
        removeNotif(index) {
            this.$store.commit('removeNotif', index)
        }
    }
}
</script>

<style lang="scss" scoped>
    .notifs-container {
        position:fixed;
        right:0;
        bottom:2%;
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
        max-height: 90%;
        z-index:9999;

    }

    .notif-item {
        position: relative;
        display: flex;
        justify-content: center;
        padding:20px;
        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        width:250px;
        max-width: 100%;
        margin:5px 0;
        opacity: 0;
    }

    .notif-content {
        color:#fff;
        font-size:13px;
        font-display: $font;
        padding:0 10px;
        max-width: calc(100% - 20px);
        overflow: auto;
        max-height: 90vh;
    }

    .notif-item.success {
        background: $success;
    }

    .notif-item.error {
        background: $error;
    }

    .notif-close {
        position: absolute;
        top:8px;
        right:8px;
        height:15px;
        width:15px;
        background-image: url('./../assets/icon-close.png');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        cursor:pointer;
    }

    /* animation */
    .notif-item.success {
        animation : success 5s;
    }

    @keyframes success {
        0% {
            margin-right: -400px;
            opacity: 0;
        }
        15% {
            margin-right:0;
            opacity: 1;
        }
        85% {
            margin-right:0;
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }

    .notif-item.error {
        animation : error 0.5s;
        opacity: 1;
    }

    @keyframes enter {
        0% {
            margin-right: -400px;
            opacity: 0;
        }
        100% {
            margin-right: 0;
            opacity: 1;
        }
    }
</style>