import Vue from 'vue'
import Router from 'vue-router'
import store from './store'

Vue.use(Router)

const router =  new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
        return { selector: to.hash }
    } else if (savedPosition) {
        return savedPosition;
    } else {
        return { x: 0, y: 0 }
    }
  },
  routes: [
    {
      path: '*',
      redirect: '/'
    },
    {
      path: '/',
      name: 'Login',
      component: () => import( './views/Login.vue'),
      meta: {
        requiresAuth: false
      },
    },
    {
      path: '/mentions-legales',
      name: 'LegalNotices',
      component: () => import( './views/LegalNotices.vue'),
      meta: {
        requiresAuth: false
      }
    },
    {
      path: '/mot-de-passe/reinitalisation/:token',
      name: 'ResetPwd',
      component: () => import( './views/ResetPassword.vue'),
      meta: {
        requiresAuth: false
      }
    },
    /* PATIENTS */
    {
      path: '/mes-pathologies',
      name: 'PathologyList',
      component: () => import( './views/patient/PathologyList.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 4
      },
    },
    {
      path: '/activation-pathologie/:id',
      name: 'PathologyValidation',
      component: () => import( './views/patient/PathologyValidation.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 4
      },
    },
    {
      path: '/pathologie/:id/:step?',
      name: 'Pathology',
      component: () => import( './views/patient/Pathology.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 4
      },
    },
    {
      path: '/mon-compte',
      name: 'MyAccount',
      component: () => import( './views/patient/MyAccount.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 4
      },
    },
    /* ADMIN */
    {
      path: '/admin/utilisateurs-liste',
      name: 'UsersList',
      component: () => import( './views/admin/UsersList.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 1
      },
    },
    {
      path: '/admin/statistiques',
      name: 'Statistiques',
      component: () => import( './views/admin/Statistiques.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 1
      },
    },
    /* COORDINATOR */
    {
      path: '/coordinateur/utilisateurs-liste',
      name: 'UsersListCoordinator',
      component: () => import( './views/coordinator/UsersList.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 2
      },
    },
    {
      path: '/coordinateur/statistiques',
      name: 'StatistiquesCoordinator',
      component: () => import( './views/coordinator/Statistiques.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 2
      },
    },
    {
      path: '/coordinateur/suivi/:id/:step?',
      name: 'MonitoringCoordinator',
      component: () => import( './views/coordinator/PathologyMonitoring.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 2
      },
    },
    {
      path: '/coordinateur/pathologies-liste/:id',
      name: 'PatologyListCoordinator',
      component: () => import( './views/coordinator/PathologyList'),
      meta: {
        requiresAuth: true,
        requiresRole: 2
      },
    },

    /* PRATICIEN */
    {
      path: '/praticien/utilisateurs-liste',
      name: 'UsersListDoctor',
      component: () => import( './views/doctor/UsersList.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 3
      },
    },
    {
      path: '/praticien/statistiques',
      name: 'StatistiquesDoctor',
      component: () => import( './views/doctor/Statistiques.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 3
      },
    },
    {
      path: '/praticien/suivi/:id/:step?',
      name: 'MonitoringDoctor',
      component: () => import( './views/doctor/PathologyMonitoring.vue'),
      meta: {
        requiresAuth: true,
        requiresRole: 3
      },
    },
    {
      path: '/praticien/pathologies-liste/:id',
      name: 'PatologyListDoctor',
      component: () => import( './views/doctor/PathologyList'),
      meta: {
        requiresAuth: true,
        requiresRole: 3
      },
    },
    
  ],
  
})

/* verification qu'on est connecté sinon on redirige */
router.beforeEach((to, from, next) => {
  if(to.matched.some(record => record.meta.requiresAuth)) {
    if(to.matched.some(record => record.meta.requiresRole === store.state.USER.role) ) {
      next()
      return
    } 
    next('/')
  }else{
    next() 
  }  
})


export default router;
