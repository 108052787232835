import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'
import './vee-validate'
import axios from 'axios'
import VTooltip from 'v-tooltip'

Vue.use(VTooltip)

Vue.config.productionTip = false

axios.interceptors.response.use(response => {
  if(response.status != 401)
    return response;
  else {
    store.commit('logout')
    store.dispatch('addNotif', {type : 2, content : 12})    
    router.push('/')
  }
    
  
  
});

new Vue({
  router :router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')

