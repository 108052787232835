<template>
    <header>
        <div class='header-main'>
            <div class="header-container">
                <div class="header-logo" v-if="this.$store.state.USER.role != 4">
                    <img src="./../assets/logo-remedex.png" alt="logo Remedex" />
                </div>
                <router-link class="header-logo" v-else :to="{ name : 'PathologyList'}">
                    <img src="./../assets/logo-remedex.png" alt="logo Remedex" />
                </router-link>
                <div class="menu">
                    <router-link :to="{ name : 'MyAccount'}" class="menu-item my-account" v-if="this.$store.state.USER.role === 4">
                        <img src="./../assets/account.png" alt="icon account" />
                        <p>{{ $t('account.myAccount')}}</p>
                    </router-link>
                    <div class="menu-item logout" @click="logout">
                        <img src="./../assets/logout.png" alt="icon logout" />
                        <p>{{ $t('account.logout')}}</p>
                    </div>
                </div>
            </div>
        </div>

        <MenuAdmin
            v-if="
                this.$route.name == 'Statistiques' ||
                this.$route.name == 'UsersList'
            "
        >
        </MenuAdmin>

        <MenuCoordinator
            v-if="
                this.$route.name == 'StatistiquesCoordinator' ||
                this.$route.name == 'UsersListCoordinator' ||
                this.$route.name == 'MonitoringCoordinator' ||
                this.$route.name == 'PatologyListCoordinator' 
            "
        >
        </MenuCoordinator>

        <MenuDoctor
            v-if="
                this.$route.name == 'StatistiquesDoctor' ||
                this.$route.name == 'UsersListDoctor' ||
                this.$route.name == 'MonitoringDoctor' ||
                this.$route.name == 'PatologyListDoctor'
            "
        >
        </MenuDoctor>

    </header>
</template>


<script>
import MenuAdmin from './MenuAdmin'
import MenuDoctor from './MenuDoctor'
import MenuCoordinator from './MenuCoordinator'
import axios from 'axios'
import store from './../store'
import router from './../router'

export default {
    name :"HeaderApp",
    components :{ 
        MenuAdmin,
        MenuDoctor,
        MenuCoordinator
    },
    methods : {
        logout() {
            let config = {
                validateStatus: () => true,
            }
            if(store.getters.getToken) {
                config.headers = { Authorization: `Bearer ${store.getters.getToken}` }
            }

            let params = {
                token : store.getters.getToken
            }

            axios
                .post(store.getters.getURL +"logout", params, config)
                .then(function () {
                    store.commit('logout')
                    router.push('/')
                })
                .catch((err) => {
                    store.dispatch('addNotif', {type : 2, content : err})
                });
        }
    }
}
</script>

<style lang="scss" scoped>
    .header-main {
        background:#fff;
        width:100%;
        height:130px;
        box-shadow: 5px 0 5px rgba(0,0,0,0.5);
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .header-container {
        width:1600px;
        max-width: 90%;
        position:relative;
        display: flex;
        justify-content: center;
        align-items: center;
        height:130px;
        position: relative;
    }

    .header-container img {
        height:100px;
        width: auto;
    }

    .menu {
        display: flex;
        height:130px;
        align-items: center;
        position: absolute;
        right:0;
    }

    .menu-item {
        margin:0 10px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        align-items: center;
        width:120px;
        text-decoration: none;
    }

    .menu-item img {
        height:50px;
        width:50px;
        margin-bottom: 10px;
    }

    .menu-item p {
        margin:0;
        font-size: 15px;
        color:$mainColor;
        font-family: $font;
    }

    .menu-item.logout p {
        color:$error;        
    }

    .menu-item.logout p:hover {
        text-decoration: underline;
    }

    @media screen and (max-width : 768px) {
        .header-main {
            height:80px;
        }
        .header-container {
            height:80px;
            justify-content: space-between;
        }

        .header-container img {
            height:70px;
        }

        .menu {
            height:80px;
        }

        .menu-item  {
            width:60px;
        }

        .menu-item img {
            height:53px;
            width:53px;
            margin:0;
        }

        .menu-item p {
            display: none;
        }
    }


</style>