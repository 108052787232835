var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',[_c('div',{staticClass:"header-main"},[_c('div',{staticClass:"header-container"},[(this.$store.state.USER.role != 4)?_c('div',{staticClass:"header-logo"},[_c('img',{attrs:{"src":require("./../assets/logo-remedex.png"),"alt":"logo Remedex"}})]):_c('router-link',{staticClass:"header-logo",attrs:{"to":{ name : 'PathologyList'}}},[_c('img',{attrs:{"src":require("./../assets/logo-remedex.png"),"alt":"logo Remedex"}})]),_c('div',{staticClass:"menu"},[(this.$store.state.USER.role === 4)?_c('router-link',{staticClass:"menu-item my-account",attrs:{"to":{ name : 'MyAccount'}}},[_c('img',{attrs:{"src":require("./../assets/account.png"),"alt":"icon account"}}),_c('p',[_vm._v(_vm._s(_vm.$t('account.myAccount')))])]):_vm._e(),_c('div',{staticClass:"menu-item logout",on:{"click":_vm.logout}},[_c('img',{attrs:{"src":require("./../assets/logout.png"),"alt":"icon logout"}}),_c('p',[_vm._v(_vm._s(_vm.$t('account.logout')))])])],1)],1)]),(
            this.$route.name == 'Statistiques' ||
            this.$route.name == 'UsersList'
        )?_c('MenuAdmin'):_vm._e(),(
            this.$route.name == 'StatistiquesCoordinator' ||
            this.$route.name == 'UsersListCoordinator' ||
            this.$route.name == 'MonitoringCoordinator' ||
            this.$route.name == 'PatologyListCoordinator' 
        )?_c('MenuCoordinator'):_vm._e(),(
            this.$route.name == 'StatistiquesDoctor' ||
            this.$route.name == 'UsersListDoctor' ||
            this.$route.name == 'MonitoringDoctor' ||
            this.$route.name == 'PatologyListDoctor'
        )?_c('MenuDoctor'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }