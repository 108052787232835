import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from "vuex-persistedstate";
import i18n from './i18n'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['JWT_TOKEN', 'LANG', 'NOTIFS', 'USER', 'CURRENT_MODULE' ]
  })],
  state: { 
      // URL : 'http://localhost/remedex_api/public/api/',
      URL : 'https://remedexfollowup.com/Remedex_api/public/api/',
      URL_ASSESTS : 'https://remedexfollowup.com/assets',
      JWT_TOKEN : null  ,
      EXPIRED : null,
      LANG : 'fr',
      NOTIFS : [],
      USER : {
        isLoged : false,
        role : null,
        infos : {}
      },
      CURRENT_MODULE : 1   

  },
  mutations: {
    updateLocale(state, newLocale) {
      state.LANG = newLocale
    },
    addNotif(state, notif) {
      state.NOTIFS.push(notif)    
    },
    removeNotif(state, key) {
      state.NOTIFS.splice(key, 1)
    },
    setToken(state, token) {
      state.JWT_TOKEN = token
    },
    login(state, user) {
      state.USER.isLoged = true
      state.USER.role = user.role
      state.USER.infos = user.infos
      state.EXPIRED = user.expired_at
    },
    logout(state){
      state.JWT_TOKEN = null
      state.NOTIFS = []
      state.USER = {
        isLoged : false,
        role : null,
        infos : {}
      }
    },
    updateUser(state, user) {
      state.USER.infos = user
    },
    setCurrentModule(state, step) {
      state.CURRENT_MODULE = step
    }
  },
  getters: {
    getURL(state) {
      return state.URL
    },
    getToken(state) {
      return state.JWT_TOKEN
    },
    getCurrentModule(state) {
      return state.CURRENT_MODULE
    }
  }, 
  actions: {
    changeLocale({ commit }, newLocale) {
      i18n.locale = newLocale 
      commit('updateLocale', newLocale)
    },
    addNotif({commit, state}, notif) {
      commit('addNotif', notif)
      const key = state.NOTIFS.length - 1
      setTimeout(() => {
        commit('removeNotif', key)
      }, 5000)

    }
  }
})