<template>
    <div class="menu-admin">
        <router-link class="menu-admin-item" :to="{name : 'StatistiquesDoctor'}">
            <div class="menu-icon icon-stats"></div>
            <p>{{ $t('admin.menu.stats')}}</p>
        </router-link>
        <router-link class="menu-admin-item" :to="{name : 'UsersListDoctor'}">
            <div class="menu-icon icon-users"></div>
            <p>{{ $t('admin.menu.patients')}}</p>
        </router-link>
    </div>
</template>

<script>
export default {
    name : 'MenuAdmin'
}
</script>

<style lang="scss" scoped>
    .menu-admin {
        display: flex;
        justify-content: center;
        margin:20px auto;
    }

    .menu-admin-item {
        display: flex;
        margin:0 20px;
        flex-direction: column;
        align-items: center;
        width:160px;
        text-decoration: none;
        cursor: pointer;
    }

    .menu-icon {
        width:31px;
        height:31px;
        margin-bottom: 5px;
        transition:0.5s;
    }

    .icon-stats {
        background-image: url('./../assets/statistiques.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .icon-users {
        background-image: url('./../assets/users.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .menu-admin-item p {
        text-align: center;
        margin:0;
        font-size:20px;
        color:$textColor;
        transition:0.5s;
        line-height: 25px;
    }

    .menu-admin-item:hover .icon-stats, .router-link-exact-active .icon-stats {
        background-image: url('./../assets/statistiques-o.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .menu-admin-item:hover .icon-users, .router-link-exact-active .icon-users {
        background-image: url('./../assets/users-o.png');
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .menu-admin-item:hover p, .router-link-exact-active p {
        color:$mainColor;
        font-family: $fontMedium;
    }
</style>